import * as React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import HPPLayout from "../../components/layouts/layout-hpp"
import Seo from "../../components/seo"

const openIsiTray = event => {
    event.preventDefault()
    setTimeout(function () {
        var psp = document.querySelector("#psp-panel")
        psp.setAttribute("data-state", "tallbutcollapse")
        document.body.classList.add("no-overflow")
    }, 300)
}

const SitemapPage = ({ location }) => (
    <HPPLayout
        location={location}
        locationHostName={location.hostname}
        jobCode="PP-AUR-US-1407 (v8.0)"
        jobCodeDate="09/24"
    >
        <Helmet>
            <html lang="en" />
            <link
                rel="canonical"
                href="https://www.auryxiahcp.com/hyperphosphatemia/sitemap"
            />
            <body className="hpp" id="hpp-hcp-sitemap" />
        </Helmet>

        <Seo
            title="Site map"
            description="Site map information for AURYXIA hyperphosphatemia site. See Prescribing Information and Full Safety Information."
        />

        <section id="sitemap" className="flood-hp col-10 center">
            <div className="flex-row">
                <div>
                    <h1>SITE MAP</h1>

                    <div className="flex-row">
                        <div>
                            <ul>
                                <li>
                                    <Link
                                        className="hpp"
                                        to="/hyperphosphatemia-treatment"
                                    >
                                        Home
                                    </Link>
                                </li>
                            </ul>

                            <ul>
                                <li>
                                    <strong>About AURYXIA</strong>
                                    <ul>
                                        <li>
                                            <Link
                                                className="hpp"
                                                to="/hyperphosphatemia/mechanism-of-action"
                                            >
                                                Mechanism of Action
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hpp"
                                                to="/hyperphosphatemia/patient-profiles"
                                            >
                                                Patient Profiles
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hpp"
                                                to="/hyperphosphatemia/resources"
                                            >
                                                Resources
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>

                            <ul>
                                <li>
                                    <Link
                                        className="hpp"
                                        to="/hyperphosphatemia/ferric-citrate-efficacy"
                                    >
                                        Efficacy
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <strong>Safety</strong>
                                    <br />
                                    <ul>
                                        <li>
                                            <Link
                                                className="hpp"
                                                to="/hyperphosphatemia/ferric-citrate-reactions"
                                            >
                                                Adverse Reactions
                                            </Link>
                                        </li>
                                        <li>
                                            <Link
                                                className="hpp"
                                                to="/hyperphosphatemia/pharmacodynamics"
                                            >
                                                Pharmacodynamics
                                            </Link>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link
                                        className="hpp"
                                        to="/hyperphosphatemia/dosing"
                                    >
                                        Dosing
                                    </Link>
                                </li>
                            </ul>
                            <ul>
                                <li>
                                    <Link
                                        className="hpp"
                                        to="/hyperphosphatemia/patient-access"
                                    >
                                        Access
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div>
                            <ul>
                                <li>
                                    <Link
                                        className="hpp"
                                        to="#isi"
                                        onClick={openIsiTray}
                                    >
                                        Important Safety Information
                                    </Link>
                                </li>
                                <li>
                                    <a
                                        className="hpp"
                                        target="_blank"
                                        href="https://akebia.com/connect/"
                                    >
                                        Contact Us
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="hpp"
                                        target="_blank"
                                        href="https://akebia.com/termsofuse/"
                                    >
                                        Terms &amp; Conditions
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="hpp"
                                        target="_blank"
                                        href="https://akebia.com/privacy-policy/"
                                    >
                                        Privacy Policy
                                    </a>
                                </li>
                                <li>
                                    <a
                                        className="hpp"
                                        target="_blank"
                                        href="https://akebia.com/"
                                    >
                                        Akebia.com
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </HPPLayout>
)

export default SitemapPage
